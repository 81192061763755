import { FC, useEffect, useState } from "react";
import { SigmaContainer, ZoomControl, FullScreenControl } from "react-sigma-v2";
import { omit, mapValues, keyBy, constant } from "lodash";

import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";

import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
import { Dataset, FiltersState, TweetText } from "../types";
import ClustersPanel from "./ClustersPanel";
import CountriesPanel from "./CountriesPanel";
import CategoriesPanel from "./CategoriesPanel";
import SearchField from "./SearchField";
import drawLabel from "../canvas-utils";
import GraphTitle from "./GraphTitle";
import DetailModal from "./DetailModal";
import Slider from "rc-slider";

import { GrClose } from "react-icons/gr";
import { BiRadioCircleMarked, BiBookContent } from "react-icons/bi";
import {
  BsArrowsFullscreen,
  BsFullscreenExit,
  BsZoomIn,
  BsZoomOut,
} from "react-icons/bs";

const Root: FC = () => {
  const [showContents, setShowContents] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [dataReady, setDataReady] = useState(false);
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [filtersState, setFiltersState] = useState<FiltersState>({
    clusters: {},
    tags: {},
    countries: {},
    categories: {},
    simulationTime: 0,
  });
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);

  //const [sliderTimeline, setSliderTimeline] = useState<number>(0)
  const [simulationPause, setSimulationPause] = useState<boolean>(true);

  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [clickedNode, setClickedNode] = useState<{
    key: string;
    label: string;
    tweets: TweetText[];
    followerCount: number;
    cluster: number;
    neigbors: string[];
  }>({
    key: "1",
    label: "1",
    tweets: [
      {
        post_type: "",
        retweetUserScreenName: "",
        tweet_text: "",
        retweetUserFollowerCount: 0,
        retweetUserFavouriteCount: 0,
      },
    ],
    followerCount: 1,
    cluster: 1,
    neigbors: ["1"],
  });

  const onSliderChange = (e: any) => {
    setFiltersState((filters) => ({
      ...filters,
      simulationTime: e,
    }));
  };
  const run_button_click = () => {
    if (simulationPause) {
      if (
        filtersState.simulationTime >=
        dataset?.simulationTimeline.maxSimulationTime!
      ) {
        setSimulationPause(!simulationPause);
        setFiltersState((filters) => ({
          ...filters,
          simulationTime: dataset?.simulationTimeline.minSimulationTime!,
        }));
      } else {
        setSimulationPause(!simulationPause);
        setFiltersState((filters) => ({
          ...filters,
          simulationTime: filters.simulationTime + 1,
        }));
      }
    } else {
      setSimulationPause(!simulationPause);
      setFiltersState((filters) => ({
        ...filters,
        simulationTime: filters.simulationTime + 1,
      }));
    }
  };

  const renderLoader = () => {
    return (
      <div className="loaderContainer position-absolute d-flex justify-content-center align-items-center">
        <div className="spinner-grow mx-2 text-primary" />
        <div className="spinner-grow mx-2 text-secondary" />
        <div className="spinner-grow mx-2 text-success" />
        <div className="spinner-grow mx-2 text-warning" />
      </div>
    );
  };
  const renderErrorText = () => {
    return (
      <div className="loaderContainer position-absolute d-flex justify-content-center align-items-center">
        <div className="alert alert-danger">{errorText}</div>
      </div>
    );
  };

  const urlParams = new URLSearchParams(window.location.search);
  const jsonUrl = urlParams.get("jsonUrl");
  // Load data on mount:
  useEffect(() => {
    if (jsonUrl) {
      fetch(`${jsonUrl}`)
        .then((res) => res.json())
        .then((dataset: Dataset) => {
          if (dataset) {
            setErrorText("");
            setDataset(dataset);
            setFiltersState({
              clusters: mapValues(
                keyBy(dataset.clusters, "key"),
                constant(true)
              ),
              tags: mapValues(keyBy(dataset.tags, "key"), constant(true)),
              countries: mapValues(
                keyBy(dataset.countries, "key"),
                constant(true)
              ),
              categories: mapValues(
                keyBy(dataset.categories, "key"),
                constant(true)
              ),
              simulationTime: dataset.simulationTimeline.maxSimulationTime,
            });
            requestAnimationFrame(() => setDataReady(true));
          } else {
            setErrorText("Geçersiz veri...");
          }
        })
        .catch(() => {
          setErrorText(
            "Geçersiz url. Direk erişim yerine projeler içerisinden ulaşmayı deneyin."
          );
        });
    } else {
      setErrorText(
        "Data url bulunamadı. Direk erişim yerine projeler içerisinden ulaşmayı deneyin."
      );
    }
  }, [jsonUrl]);

  useEffect(() => {
    if (!simulationPause) {
      if (
        filtersState.simulationTime <
        dataset?.simulationTimeline.maxSimulationTime!
      ) {
        let slider = setInterval(() => {
          setFiltersState((filters) => ({
            ...filters,
            simulationTime: filters.simulationTime + 3000,
          }));
          //setSliderTimeline(sliderTimeline + 3000);
        }, 100);
        return () => clearInterval(slider);
      } else {
        setFiltersState((filters) => ({
          ...filters,
          simulationTime: dataset?.simulationTimeline.maxSimulationTime!,
        }));
        //setSliderTimeline(max_timeline)
        setSimulationPause(!simulationPause);
      }
    } else {
      return () => {};
    }
  }, [filtersState]);

  if (errorText) return renderErrorText();
  if (!dataset) return renderLoader();

  return (
    <div id="app-root" className={showContents ? "show-contents" : ""}>
      {detailModal && (
        <>
          <DetailModal
            clickedNode={clickedNode}
            setDetailModal={setDetailModal}
          />
        </>
      )}
      <SigmaContainer
        graphOptions={{ type: "directed" }}
        initialSettings={{
          nodeProgramClasses: { image: getNodeProgramImage() },
          labelRenderer: drawLabel,
          // defaultNodeType: "label",
          defaultEdgeType: "arrow",
          labelDensity: 0.07,
          labelGridCellSize: 120,
          labelRenderedSizeThreshold: 30,
          labelFont: "Lato, sans-serif",
          zIndex: true,
        }}
        className="react-sigma"
      >
        <GraphSettingsController hoveredNode={hoveredNode} />
        <GraphEventsController
          setDetailModal={setDetailModal}
          setClickedNode={setClickedNode}
          setHoveredNode={setHoveredNode}
        />
        <GraphDataController dataset={dataset} filters={filtersState} />

        {dataReady && (
          <>
            <div className="controls">
              <div className="ico">
                <button
                  type="button"
                  className="show-contents"
                  onClick={() => setShowContents(true)}
                  title="Show caption and description"
                >
                  <BiBookContent />
                </button>
              </div>
              <FullScreenControl
                className="ico"
                customEnterFullScreen={<BsArrowsFullscreen />}
                customExitFullScreen={<BsFullscreenExit />}
              />
              <ZoomControl
                className="ico"
                customZoomIn={<BsZoomIn />}
                customZoomOut={<BsZoomOut />}
                customZoomCenter={<BiRadioCircleMarked />}
              />
            </div>
            <div className="contents">
              <div className="ico">
                <button
                  type="button"
                  className="ico hide-contents"
                  onClick={() => setShowContents(false)}
                  title="Show caption and description"
                >
                  <GrClose />
                </button>
              </div>
              <GraphTitle filters={filtersState} />
              <div className="panels">
                <SearchField filters={filtersState} />
                <ClustersPanel
                  clusters={dataset.clusters}
                  filters={filtersState}
                  setClusters={(clusters) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters,
                    }))
                  }
                  toggleCluster={(cluster) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters: filters.clusters[cluster]
                        ? omit(filters.clusters, cluster)
                        : { ...filters.clusters, [cluster]: true },
                    }));
                  }}
                />
                <CountriesPanel
                  countries={dataset.countries}
                  filters={filtersState}
                  setCountries={(countries) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      countries,
                    }))
                  }
                  toggleCountry={(country) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      countries: filters.countries[country]
                        ? omit(filters.countries, country)
                        : { ...filters.countries, [country]: true },
                    }));
                  }}
                />
                <CategoriesPanel
                  categories={dataset.categories}
                  filters={filtersState}
                  setCategories={(categories) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      categories,
                    }))
                  }
                  toggleCategory={(category) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      categories: filters.categories[category]
                        ? omit(filters.categories, category)
                        : { ...filters.categories, [category]: true },
                    }));
                  }}
                />
              </div>
            </div>
            <div className=" fixed-bottom">
              <div className="d-flex flex-row w-100 simulatorpanel2">
                <button
                  onClick={run_button_click}
                  type="button"
                  className="btn m-2 p-0 col-1 border-0 my-own-panel-button"
                >
                  R
                </button>
                <div className="col-9 d-flex justify-content-center align-items-center">
                  <Slider
                    min={dataset?.simulationTimeline.minSimulationTime!}
                    max={dataset?.simulationTimeline.maxSimulationTime!}
                    value={filtersState.simulationTime}
                    onChange={onSliderChange}
                  />
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  {new Date(
                    filtersState.simulationTime * 1000
                  ).toLocaleDateString("tr-TR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </SigmaContainer>
    </div>
  );
};

export default Root;
