import React from "react";
import ReactDOM from "react-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import "react-sigma-v2/lib/react-sigma-v2.css";
import "./styles.css";
import 'rc-slider/assets/index.css';

import Root from "./views/Root";



ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root"),
);
