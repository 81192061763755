import { DialogContentProps } from "../../types";
import * as Styled from "./DialogContent.styled";

type Props = DialogContentProps;

const DialogContent = (props: Props) => {
  return (
    <Styled.DialogContentContainer>
      <div className="content">
        <div className="content-user">
          <div className="content-user-row">
            <div className="content-user-col1">Kullanıcı Adı:</div>
            <div className="content-user-col2">{props.data.label}</div>
          </div>
          <div className="content-user-row">
            <div className="content-user-col1">Takipçi Sayısı:</div>
            <div className="content-user-col2">
              {props?.data?.followerCount}
            </div>
          </div>
          <div className="content-user-row">
            <div className="content-user-col1">Grup:</div>
            <div className="content-user-col2">{props?.data?.cluster}</div>
          </div>
        </div>
      </div>
      <div className="content">
        <h3 className="content-title">Bağlantılar</h3>
        <div className="content-connections">
          {props?.data?.neigbors?.map((connection, index) => (
            <div className="content-connections-connection" key={index}>
              <div>{connection} </div>
              {/* <div>({connection.followers})</div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="content">
        <h3 className="content-title">Tweetler</h3>
        <div className="content-tweets">
          {props?.data?.tweets?.map((tweet, index) => (
            <div className="content-tweets-tweet-container" key={index}>
              <div className="content-tweets-tweet">
                <div className="content-tweets-user">
                  <div className="content-x-logo">
                    <img width={18} height={18} src={"/images/x.png"} alt="x" />
                  </div>
                  <div className="content-tweets-user-name">
                    @{tweet.retweetUserScreenName}
                  </div>
                </div>
                <p>{tweet.tweet_text}</p>
                <div className="content-tweets-info">
                  <div className="content-tweets-info-logo">
                    <img
                      width={18}
                      height={18}
                      src={"/images/hearth.png"}
                      alt="x"
                    />
                    <span>{tweet.retweetUserFollowerCount}</span>
                  </div>
                  <div className="content-tweets-info-logo">
                    <img
                      width={18}
                      height={18}
                      src={"/images/retweet.png"}
                      alt="x"
                    />
                    <span>{tweet.retweetUserFavouriteCount}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styled.DialogContentContainer>
  );
};

export default DialogContent;
