import { useRegisterEvents, useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";
import { sortBy, map } from "lodash";

import {TweetText} from "../types";


function getMouseLayer() {
  return document.querySelector(".sigma-mouse");
}

const GraphEventsController: FC<{setDetailModal:(show:boolean)=>void, setClickedNode:(node: { key:string, label:string, tweets:TweetText[], followerCount:number, cluster:number, neigbors:string[] })=>void ,setHoveredNode: (node: string | null) => void }> = ({ setDetailModal, setClickedNode, setHoveredNode, children }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode({ node }) {
        if (!graph.getNodeAttribute(node, "hidden")) {
          //window.open(graph.getNodeAttribute(node, "URL"), "_blank");
          const neighbor_nodes = graph.neighbors(node)
          const top10neighbors = sortBy(neighbor_nodes, [function(o) { return -graph.getNodeAttribute(o, "userFollowerCount"); }]).slice(0, 10);
          const neigborsFull = map(top10neighbors, (node)=>(graph.getNodeAttribute(node, "label") + ' - ' + graph.getNodeAttribute(node, "userFollowerCount")))

          setClickedNode({key:node, label: graph.getNodeAttribute(node, "label"), tweets: graph.getNodeAttribute(node, "tweet_text"), followerCount: graph.getNodeAttribute(node, "userFollowerCount"), cluster: graph.getNodeAttribute(node, "cluster")+1, neigbors: neigborsFull})
          setDetailModal(true)
        }
      },
      enterNode({ node }) {
        setHoveredNode(node);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
      },
      leaveNode() {
        setHoveredNode(null);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
      },
      wheelStage(){
        graph.edges().forEach((edge)=>graph.getEdgeAttributes(edge).size = 0)
      }
    });
  }, []);

  return <>{children}</>;
};

export default GraphEventsController;
