import styled from "styled-components";

export const DialogContentContainer = styled.div`
  height: 100%;
  width: 100%;

  .content {
    overflow: scroll;
    &-user {
      &-row {
        display: flex;
        padding: 1rem 0;
        border-bottom: 1px solid #e5e5e5;
      }
      &-col1 {
        width: 150px;
        font-weight: normal;
        line-height: 21px;
      }
      &-col2 {
        color: #a183c5;
        font-weight: 700;
        line-height: 21px;
      }
    }
    &-title {
      padding: 0.5rem 0;
      font-weight: 700;
      font-size: 1rem;
      line-height: 21px;
      color: #a183c5;
    }
    &-connections {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
      gap: 1rem;
      &-connection {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        border: 1px solid #e5e5e5;
        border-radius: 0.2rem;
        align-items: center;
        justify-content: center;
      }
    }
    &-tweets {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &-tweet-container {
        width: 100%;
        display: flex;
        gap: 1rem;
        border: 1px solid #e5e5e5;
        border-radius: 0.2rem;
        padding: 1rem 2rem;
      }
      &-tweet {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0.2rem;
      }
      &-user {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &-name {
          font-weight: 400;
          font-size: 12px;
        }
      }
      &-info {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        &-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin: 0px 5px;
          }
        }
      }
    }
    &-x-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem;
      border-radius: 50%;
      margin-right: 3px;
      background-color: black;
    }
  }
  .last {
    border-right: 1px solid #e5e5e5;
  }
`;
