import React, { FC, useEffect, useMemo, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { sortBy, values, keyBy, mapValues } from "lodash";
import { MdGroupWork } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import { Category, FiltersState } from "../types";
import Panel from "./Panel";

const CategoriesPanel: FC<{
    categories: Category[];
    filters: FiltersState;
    toggleCategory: (category: string) => void;
    setCategories: (categories: Record<string, boolean>) => void;
}> = ({ categories, filters, toggleCategory, setCategories }) => {
    const sigma = useSigma();
    const graph = sigma.getGraph();

    const nodesPerCategory = useMemo(() => {
        const index: Record<string, number> = {};
        graph.forEachNode((_, { category }) => (index[category] = (index[category] || 0) + 1));
        return index;
    }, []);

    const maxNodesPerCategory = useMemo(() => Math.max(...values(nodesPerCategory)), [nodesPerCategory]);
    const visibleCategoriesCount = useMemo(() => Object.keys(filters.categories).length, [filters]);

    const [visibleNodesPerCategory, setVisibleNodesPerCategory] = useState<Record<string, number>>(nodesPerCategory);
    useEffect(() => {
        // To ensure the graphology instance has up to data "hidden" values for
        // nodes, we wait for next frame before reindexing. This won't matter in the
        // UX, because of the visible nodes bar width transition.
        requestAnimationFrame(() => {
            const index: Record<string, number> = {};
            graph.forEachNode((_, { category, hidden }) => !hidden && (index[category] = (index[category] || 0) + 1));
            setVisibleNodesPerCategory(index);
        });
    }, [filters]);

    const sortedCategories = useMemo(
        () => sortBy(categories, (category) => -nodesPerCategory[category.key]),
        [categories, nodesPerCategory],
    );

    return (
        <Panel
            title={
                <>
                    <MdGroupWork className="text-muted" /> Categories
                    {visibleCategoriesCount < categories.length ? (
                        <span className="text-muted text-small">
              {" "}
                            ({visibleCategoriesCount} / {categories.length})
            </span>
                    ) : (
                        ""
                    )}
                </>
            }
        >
            <p>
                <i className="text-muted">Click a category to show/hide related pages from the network.</i>
            </p>
            <p className="buttons">
                <button className="btn" onClick={() => setCategories(mapValues(keyBy(categories, "key"), () => true))}>
                    <AiOutlineCheckCircle /> Check all
                </button>{" "}
                <button className="btn" onClick={() => setCategories({})}>
                    <AiOutlineCloseCircle /> Uncheck all
                </button>
            </p>
            <ul>
                {sortedCategories.map((category) => {
                    const nodesCount = nodesPerCategory[category.key];
                    const visibleNodesCount = visibleNodesPerCategory[category.key] || 0;
                    return (
                        <li
                            className="caption-row"
                            key={category.key}
                            title={`${nodesCount} page${nodesCount > 1 ? "s" : ""}${
                                visibleNodesCount !== nodesCount ? ` (only ${visibleNodesCount} visible)` : ""
                            }`}
                        >
                            <input
                                type="checkbox"
                                checked={filters.categories[category.key] || false}
                                onChange={() => toggleCategory(category.key)}
                                id={`category-${category.key}`}
                            />
                            <label htmlFor={`category-${category.key}`}>
                                <span className="circle" style={{ background: '#f1f1f1', borderColor: '#f1f1f1' }} />{" "}
                                <div className="node-label">
                                    <span>{category.label}</span>
                                    <div className="bar" style={{ width: (100 * nodesCount) / maxNodesPerCategory + "%" }}>
                                        <div
                                            className="inside-bar"
                                            style={{
                                                width: (100 * visibleNodesCount) / nodesCount + "%",
                                            }}
                                        />
                                    </div>
                                </div>
                            </label>
                        </li>
                    );
                })}
            </ul>
        </Panel>
    );
};

export default CategoriesPanel;
