import React, { FC, useEffect, useMemo, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { sortBy, values, keyBy, mapValues } from "lodash";
import { MdGroupWork } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import { Country, FiltersState } from "../types";
import Panel from "./Panel";

const CountriesPanel: FC<{
    countries: Country[];
    filters: FiltersState;
    toggleCountry: (country: string) => void;
    setCountries: (countries: Record<string, boolean>) => void;
}> = ({ countries, filters, toggleCountry, setCountries }) => {
    const sigma = useSigma();
    const graph = sigma.getGraph();

    const nodesPerCountry = useMemo(() => {
        const index: Record<string, number> = {};
        graph.forEachNode((_, { country }) => (index[country] = (index[country] || 0) + 1));
        return index;
    }, []);

    const maxNodesPerCountry = useMemo(() => Math.max(...values(nodesPerCountry)), [nodesPerCountry]);
    const visibleCountriesCount = useMemo(() => Object.keys(filters.countries).length, [filters]);

    const [visibleNodesPerCountry, setVisibleNodesPerCountry] = useState<Record<string, number>>(nodesPerCountry);
    useEffect(() => {
        // To ensure the graphology instance has up to data "hidden" values for
        // nodes, we wait for next frame before reindexing. This won't matter in the
        // UX, because of the visible nodes bar width transition.
        requestAnimationFrame(() => {
            const index: Record<string, number> = {};
            graph.forEachNode((_, { country, hidden }) => !hidden && (index[country] = (index[country] || 0) + 1));
            setVisibleNodesPerCountry(index);
        });
    }, [filters]);

    const sortedCountries = useMemo(
        () => sortBy(countries, (country) => -nodesPerCountry[country.key]),
        [countries, nodesPerCountry],
    );

    return (
        <Panel
            title={
                <>
                    <MdGroupWork className="text-muted" /> Countries
                    {visibleCountriesCount < countries.length ? (
                        <span className="text-muted text-small">
              {" "}
                            ({visibleCountriesCount} / {countries.length})
            </span>
                    ) : (
                        ""
                    )}
                </>
            }
        >
            <p>
                <i className="text-muted">Click a country to show/hide related pages from the network.</i>
            </p>
            <p className="buttons">
                <button className="btn" onClick={() => setCountries(mapValues(keyBy(countries, "key"), () => true))}>
                    <AiOutlineCheckCircle /> Check all
                </button>{" "}
                <button className="btn" onClick={() => setCountries({})}>
                    <AiOutlineCloseCircle /> Uncheck all
                </button>
            </p>
            <ul>
                {sortedCountries.map((country) => {
                    const nodesCount = nodesPerCountry[country.key];
                    const visibleNodesCount = visibleNodesPerCountry[country.key] || 0;
                    return (
                        <li
                            className="caption-row"
                            key={country.key}
                            title={`${nodesCount} page${nodesCount > 1 ? "s" : ""}${
                                visibleNodesCount !== nodesCount ? ` (only ${visibleNodesCount} visible)` : ""
                            }`}
                        >
                            <input
                                type="checkbox"
                                checked={filters.countries[country.key] || false}
                                onChange={() => toggleCountry(country.key)}
                                id={`country-${country.key}`}
                            />
                            <label htmlFor={`country-${country.key}`}>
                                <span className="circle" style={{ background: '#f1f1f1', borderColor: '#f1f1f1' }} />{" "}
                                <div className="node-label">
                                    <span>{country.label}</span>
                                    <div className="bar" style={{ width: (100 * nodesCount) / maxNodesPerCountry + "%" }}>
                                        <div
                                            className="inside-bar"
                                            style={{
                                                width: (100 * visibleNodesCount) / nodesCount + "%",
                                            }}
                                        />
                                    </div>
                                </div>
                            </label>
                        </li>
                    );
                })}
            </ul>
        </Panel>
    );
};

export default CountriesPanel;
