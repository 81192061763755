import { FC } from "react";
import { TweetText } from "../types";
import DialogContent from "./dialog/DialogContent";

const DetailModal: FC<{
  clickedNode: {
    key: string;
    label: string;
    tweets: TweetText[];
    followerCount: number;
    cluster: number;
    neigbors: string[];
  };
  setDetailModal: (show: boolean) => void;
}> = ({ clickedNode, setDetailModal }) => {
  return (
    <div
      className="modal fade bd-example-modal-xl show d-block detail-modal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {clickedNode.label}
            </h5>
            <button
              type="button"
              className="btn-close btn-close-custom"
              onClick={() => setDetailModal(false)}
            />
          </div>
          <div className="modal-body">
            <DialogContent data={clickedNode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailModal;
