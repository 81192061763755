import { useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";
import { keyBy, omit } from "lodash";

import { Dataset, FiltersState } from "../types";

const GraphDataController: FC<{ dataset: Dataset; filters: FiltersState }> = ({
  dataset,
  filters,
  children,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  /**
   * Feed graphology with the new dataset:
   */
  useEffect(() => {
    if (!graph || !dataset) return;

    const clusters = keyBy(dataset.clusters, "key");
    const tags = keyBy(dataset.tags, "key");

    dataset.nodes.forEach((node) =>
      graph.addNode(node.key, {
        ...node,
        ...omit(clusters[node.cluster], "key"),
        image: `${process.env.PUBLIC_URL}/images/${tags[node.tag].image}`,
      })
    );
    //console.log(clusters[dataset.nodes.filter((node) => node.key == "1638231860")[0].cluster].color)
    dataset.edges.forEach(([source, target]) =>
      graph.addEdge(target, source, {
        size: 0.5,
        color:
          clusters[
            dataset.nodes.filter((node) => node.key === target)[0].cluster
          ].color,
      })
    );

    // Use degrees as node sizes:
    const scores = graph
      .nodes()
      .map((node) => graph.getNodeAttribute(node, "score"));
    const minDegree = Math.min(...scores);
    const maxDegree = Math.max(...scores);
    const MIN_NODE_SIZE = 3;
    const MAX_NODE_SIZE = 35;
    graph.forEachNode((node) =>
      graph.setNodeAttribute(
        node,
        "size",
        ((graph.getNodeAttribute(node, "score") - minDegree) /
          (maxDegree - minDegree)) *
          (MAX_NODE_SIZE - MIN_NODE_SIZE) +
          MIN_NODE_SIZE
      )
    );

    return () => graph.clear();
  }, [graph, dataset]);

  /**
   * Apply filters to graphology:
   */
  useEffect(() => {
    const { clusters, tags, countries, categories, simulationTime } = filters;
    const filterSimulationTime = simulationTime;
    graph.forEachNode(
      (node, { cluster, tag, country, category, simulationTime }) =>
        graph.setNodeAttribute(
          node,
          "hidden",
          !clusters[cluster] ||
            !tags[tag] ||
            !countries[country] ||
            !categories[category] ||
            simulationTime >= filterSimulationTime
        )
    );
  }, [graph, filters]);

  return <>{children}</>;
};

export default GraphDataController;
